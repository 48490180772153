const rankings_data = [
  {
    id: '1',
    image: '/images/avatars/avatar_1.jpg',
    title: 'NFT Funny Cat',
    volume: '30,643.01',
    h24: '-35.75%',
    value: '-49.99%',
    floorPrice: '15.49',
    owners: '3.5K',
    items: '10.0K',
    icon: true,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Art',
    blockchain: 'Ethereum',
    postDate: 'Last 7 Days',
  },
  {
    id: '2',
    image: '/images/avatars/avatar_2.jpg',
    title: 'Cryptopank',
    volume: '18,973.17',
    h24: '+25.79%',
    value: '-10.28%',
    floorPrice: '7.57',
    owners: '6.2K',
    items: '8.2K',
    icon: false,
    h24Color: 'green',
    valueColor: 'red',
    category: 'Collectibles',
    blockchain: 'Polygon',
    postDate: 'Last 14 Days',
  },
  {
    id: '3',
    image: '/images/avatars/avatar_5.jpg',
    title: 'Bored Bunny',
    volume: '13,503.09',
    h24: '+71.77%',
    value: '-61.16%',
    floorPrice: '3.31',
    owners: '11.8K',
    items: '6.2K',
    icon: true,
    h24Color: 'green',
    valueColor: 'red',
    category: 'Virtual World',
    blockchain: 'Flow',
    postDate: 'Last 30 Days',
  },
  {
    id: '4',
    image: '/images/avatars/avatar_9.jpg',
    title: 'NFT stars',
    volume: '6,098.71',
    h24: '-35.75%',
    value: '+27.79%',
    floorPrice: '7.57',
    owners: '5K',
    items: '8.2K',
    icon: false,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Music',
    blockchain: 'Tezos',
    postDate: 'Last 60 Days',
  },
  {
    id: '5',
    image: '/images/avatars/avatar_13.jpg',
    title: 'Arcahorizons',
    volume: '4,689.20',
    h24: '-73.64%',
    value: '+97.87%',
    floorPrice: '0.55',
    owners: '3.4K',
    items: '5.2K',
    icon: false,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Photography',
    blockchain: 'Ethereum',
    postDate: 'Last 90 Days',
  },
  {
    id: '6',
    image: '/images/avatars/avatar_6.jpg',
    title: 'Wow Frens',
    volume: '4,342.51',
    h24: '-8.65%',
    value: '+58.15%',
    floorPrice: '1.84',
    owners: '5.4K',
    items: '6.2K',
    icon: true,
    h24Color: 'red',
    valueColor: 'green',
    category: 'art',
    blockchain: 'Polygon',
    postDate: 'Last 7 Days',
  },
  {
    id: '7',
    image: '/images/avatars/avatar_10.jpg',
    title: 'Asumitsu',
    volume: '3,156.28',
    h24: '+40.79%',
    value: '-55.40%',
    floorPrice: '3.75',
    owners: '2.1K',
    items: '10.1K',
    icon: true,
    h24Color: 'green',
    valueColor: 'red',
    category: 'Collectibles',
    blockchain: 'Flow',
    postDate: 'Last 14 Days',
  },

  {
    id: '8',
    image: '/images/avatars/avatar_1.jpg',
    title: 'NFT Funny Cat',
    volume: '30,643.01',
    h24: '-35.75%',
    value: '-49.99%',
    floorPrice: '15.49',
    owners: '3.5K',
    items: '10.0K',
    icon: true,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Domain',
    blockchain: 'Tezos',
    postDate: 'Last 30 Days',
  },
  {
    id: '9',
    image: '/images/avatars/avatar_2.jpg',
    title: 'Cryptopank',
    volume: '18,973.17',
    h24: '+25.79%',
    value: '-10.28%',
    floorPrice: '7.57',
    owners: '6.2K',
    items: '8.2K',
    icon: false,
    h24Color: 'green',
    valueColor: 'red',

    category: 'Music',
    blockchain: 'Ethereum',
    postDate: 'Last 60 Days',
  },
  {
    id: '10',
    image: '/images/avatars/avatar_5.jpg',
    title: 'Bored Bunny',
    volume: '13,503.09',
    h24: '+71.77%',
    value: '-61.16%',
    floorPrice: '3.31',
    owners: '11.8K',
    items: '6.2K',
    icon: false,
    h24Color: 'green',
    valueColor: 'red',
    category: 'Domain',
    blockchain: 'Polygon',
    postDate: 'Last 90 Days',
  },
  {
    id: '11',
    image: '/images/avatars/avatar_9.jpg',
    title: 'NFT stars',
    volume: '6,098.71',
    h24: '-35.75%',
    value: '+27.79%',
    floorPrice: '7.57',
    owners: '5K',
    items: '8.2K',
    icon: false,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Virtual World',
    blockchain: 'Flow',
    postDate: 'Last 7 Days',
  },
  {
    id: '12',
    image: '/images/avatars/avatar_13.jpg',
    title: 'Arcahorizons',
    volume: '4,689.20',
    h24: '-73.64%',
    value: '+97.87%',
    floorPrice: '0.55',
    owners: '3.4K',
    items: '5.2K',
    icon: false,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Art',
    blockchain: 'Tezos',
    postDate: 'Last 14 Days',
  },
  {
    id: '13',
    image: '/images/avatars/avatar_6.jpg',
    title: 'Wow Frens',
    volume: '4,342.51',
    h24: '-8.65%',
    value: '+58.15%',
    floorPrice: '1.84',
    owners: '5.4K',
    items: '6.2K',
    icon: false,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Collectibles',
    blockchain: 'Ethereum',
    postDate: 'Last 30 Days',
  },
  {
    id: '14',
    image: '/images/avatars/avatar_10.jpg',
    title: 'Asumitsu',
    volume: '3,156.28',
    h24: '+40.79%',
    value: '-55.40%',
    floorPrice: '3.75',
    owners: '2.1K',
    items: '10.1K',
    icon: true,
    h24Color: 'green',
    valueColor: 'red',
    category: 'Art',
    blockchain: 'Polygon',
    postDate: 'Last 60 Days',
  },

  {
    id: '15',
    image: '/images/avatars/avatar_1.jpg',
    title: 'NFT Funny Cat',
    volume: '30,643.01',
    h24: '-35.75%',
    value: '-49.99%',
    floorPrice: '15.49',
    owners: '3.5K',
    items: '10.0K',
    icon: true,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Domain',
    blockchain: 'Flow',
    postDate: 'Last 90 Days',
  },
  {
    id: '16',
    image: '/images/avatars/avatar_2.jpg',
    title: 'Cryptopank',
    volume: '18,973.17',
    h24: '+25.79%',
    value: '-10.28%',
    floorPrice: '7.57',
    owners: '6.2K',
    items: '8.2K',
    icon: false,
    h24Color: 'green',
    valueColor: 'red',
    category: 'Music',
    blockchain: 'Tezos',
    postDate: 'Last 7 Days',
  },
  {
    id: '17',
    image: '/images/avatars/avatar_5.jpg',
    title: 'Bored Bunny',
    volume: '13,503.09',
    h24: '+71.77%',
    value: '-61.16%',
    floorPrice: '3.31',
    owners: '11.8K',
    items: '6.2K',
    icon: false,
    h24Color: 'green',
    valueColor: 'red',
    category: 'Domain',
    blockchain: 'Ethereum',
    postDate: 'Last 14 Days',
  },
  {
    id: '18',
    image: '/images/avatars/avatar_9.jpg',
    title: 'NFT stars',
    volume: '6,098.71',
    h24: '-35.75%',
    value: '+27.79%',
    floorPrice: '7.57',
    owners: '5K',
    items: '8.2K',
    icon: false,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Domain',
    blockchain: 'Polygon',
    postDate: 'Last 30 Days',
  },
  {
    id: '19',
    image: '/images/avatars/avatar_13.jpg',
    title: 'Arcahorizons',
    volume: '4,689.20',
    h24: '-73.64%',
    value: '+97.87%',
    floorPrice: '0.55',
    owners: '3.4K',
    items: '5.2K',
    icon: false,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Virtual World',
    blockchain: 'Flow',
    postDate: 'Last 60 Days',
  },
  {
    id: '20',
    image: '/images/avatars/avatar_6.jpg',
    title: 'Wow Frens',
    volume: '4,342.51',
    h24: '-8.65%',
    value: '+58.15%',
    floorPrice: '1.84',
    owners: '5.4K',
    items: '6.2K',
    icon: false,
    h24Color: 'red',
    valueColor: 'green',
    category: 'Photography',
    blockchain: 'Tezos',
    postDate: 'Last 90 Days',
  },
];

export { rankings_data };
